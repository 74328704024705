import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchApiProviders = createAsyncThunk(
    "data/apiProviders",
    async () => {
        if (window.gregario.skipFeature.includes("data/apiProviders")) {
            return
        }
        const data = await window.gregario.getApiProviders()
        window.gregario.globalData.apiProviders = data?.results
        return data
    }
)

export const apiProviders = createSlice({
    name: "apiProviders",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchApiProviders.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchApiProviders.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchApiProviders.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default apiProviders.reducer