import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchUsersPortfolio = createAsyncThunk(
    "data/usersPortfolio",
    async () => {
        if (window.gregario.skipFeature.includes("data/usersPortfolio")) {
            return
        }
        const data = await window.gregario.userPortfolio(1)
        window.gregario.globalData.usersPortfolio = data?.results || []
        return data
    }
);

export const usersPortfolioSlice = createSlice({
    name: "usersPortfolio",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsersPortfolio.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchUsersPortfolio.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            })) || []
        })
        builder.addCase(fetchUsersPortfolio.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default usersPortfolioSlice.reducer